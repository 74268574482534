* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 14px;
    background: linear-gradient(to bottom, transparent, #eee), url(assets/images/background-form-viewer.png) no-repeat center top;
    background-size: auto, 100% auto;
}

.container {
    min-height: 100vh;
}

.button-container {
    padding: 32px 5px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    &--small {
        padding: 16px;
    }
    .buttons-set {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        width: 100%;
        max-width: 823px;
        &--space-between {
            justify-content: space-between;
        }
        &--flex-end {
            justify-content: flex-end;
        }
        &--small-gap {
            gap: 16px;
        }
        &__button {
            background: none;
            border: 2px solid #ea9800;
            border-radius: 5px;
            padding: 10px 32px;
            color: #ea9800;
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 1px;
            min-width: 90px;
            display: block;
            text-align: center;
            text-decoration: none;
            &:hover {
                background-color: #ea9800;
                color: #fff;
            }
            &:focus-visible {
                outline: 0;
            }
            &--second {
                border: 2px solid #999;
                color: #999;
                &:hover {
                    background-color: #999;
                    color: #fff;
                }
            }
            &--third {
                border: 2px solid #20843e;
                background-color: #20843e;
                color: #fff;
                &:hover {
                    background: none;
                    color: #20843e;
                }
            }
            &--small {
                padding: 8px;
            }
            &--fit-content {
                min-width: inherit;
            }
            &--small-width {
                min-width: 50px;
            }
            &--cancel {
                color: #dc3545;
                border-color: #dc3545;
                &:hover {
                    background-color: #dc3545;
                }
            }
            &--warning {
                color: #ffc107;
                border-color: #ffc107;
                &:hover {
                    background-color: #ffc107;
                }
            }
        }
    }
    &__link {
        text-decoration: none;
        font-size: 15px;
        &--cancel {
            color: #dc3545
        }
    }
}

@media (max-width: 576px) {
    .button-container {
        .buttons-set {
            gap: 16px;
        }
    }
}

dialog {
	padding: 1rem 3rem;
	background: white;
	max-width: 400px;
	padding-top: 2rem;
	border-radius: 20px;
	border: 0;
	box-shadow: 0 5px 30px 0 rgb(0 0 0 / 10%);
	animation: fadeIn 1s ease both;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
	&::backdrop {
		animation: fadeIn .5s ease both;
		background: rgba(#145529, 0.1);
		z-index: 2;
		backdrop-filter: blur(10px);
        position: fixed;
	}
	h2.title {
		font-weight: 600;
		font-size: 2rem;
		padding-bottom: 1rem;
        text-align: center;
        &--danger {
            color: #dc3545;
        }
        &--warning {
            color: #ffc107;
        }
	}
	p {
		font-size: 1rem;
		line-height: 1.3rem;
		padding: 0.5rem 0;
        text-align: center;
	}
}

@-webkit-keyframes slideInDown {
    0% {
        -webkit-transform: translate3d(0,-20%,0);
        transform: translate3d(0,-20%,0);
        visibility: visible
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes slideInDown {
    0% {
        -webkit-transform: translate3d(0,-20%,0);
        transform: translate3d(0,-20%,0);
        visibility: visible
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

.container-list {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1000px;
}

.container-filters {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 16px;
    margin: 32px auto;
    max-width: 1000px;
}

.filters {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    gap: 16px;
}

.search-bar {
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: -2px 2px 2px rgba(0,0,0,0.1);
    &__input {
        border: 0;
        height: 35px;
        border-radius: 10px 0 0 10px;
        width: 300px;
        background-color: rgba(255,255,255,0.7);
        padding: 0 16px;
        color: #666;
        &:focus-visible {
            outline: 0;
        }
    }
}

.search-bar-button {
    width: 35px;
    height: 35px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 10px 10px 0;
    background-color: #028614;
    box-shadow: 2px -2px 2px rgba(0,0,0,0.2) inset;
    cursor: pointer;
    &__icon {
        color: #fff;
        font-size: 1.2rem;
    }
}

.clear-filters {
    width: 35px;
    height: 35px;
    border: 1px solid #028614;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: transparent;
    box-shadow: -2px 2px 2px rgba(0,0,0,0.1), 1px -1px 1px rgba(0,0,0,0.2) inset;
    cursor: pointer;
    &__icon {
        color: #028614;
        font-size: 1.2rem;
    }
    &:before {
        content: '';
        position: absolute;
        top: 15px;
        left: 2px;
        width: 30px;
        height: 2px;
        background-color: #028614;
        transform: rotate(-45deg);
    }
}